import React from 'react';
import './dashboard.scss';
import {
  compress_pdf_icon,
  compress_pdf_right_icon,
  edit_pdf_icon,
  edit_pdf_right_icon,
  merge_pgf_icon,
  merge_pgf_right_icon,
  protect_pdf_icon,
  protect_pdf_right_icon,
  search_vector,
  sign_pdf_icon,
  sign_pdf_right_icon,
  split_pdf_icon,
  split_pdf_right_icon,
  upload_icon,
  rotate_pdf_icon,
  remove_pdf_icon,
  extract_pdf_icon,
  word_pdf_icon,
  to_word_pdf_icon,
  unlock_pdf_icon,
  remove_pdf_right_icon,
  extract_pdf_right_icon,
  word_pdf_right_icon,
  to_word_pdf_right_icon,
  unlock_pdf_right_icon,
  rotate_pdf_right_icon,
  convert_icon,
  download_button_icon,
  delete_button_icon,
  pdf_png_icon,
  pdf_jpg_icon,
  pdf_svg_icon,
  format_icon,
  download_file_icon,
  delete_file_icon,
  more_icon
} from '../../assets/images/dashboard';
import FileConvert from '../../components/partials/homeComponent/FilesConvert';
import { Dropdown, Menu, Table } from 'antd';
import { dashboarditems } from '../../utils/commonUtils';
const DashboardComponent = () => {
  const option = [
    {
      id: 1,
      image: format_icon,
      name: 'Convert File Format'
    },
    {
      id: 2,
      image: download_file_icon,
      name: 'Download'
    },
    {
      id: 3,
      image: delete_file_icon,
      name: 'Delete'
    }
  ];

  const fileOption = (
    <Menu>
      {option.map((item) => (
        <Menu.Item key={item.id}>
          <div className='upload-files-option'>
            <img src={item.image} alt={item.name} style={{ width: 25 }} />
            <span>{item.name}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu = (
    <Menu>
      {dashboarditems.map((item) => (
        <Menu.Item key={item.id}>
          <div className='upload-files-menu'>
            <img src={item.image} alt={item.name} style={{ width: 32 }} />
            <span>{item.name}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
  const columns = [
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (text, record) => {
        return record.fileType;
      }
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Size',
      dataIndex: 'size'
    },
    {
      title: () => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            justifyContent: 'end'
          }}
        >
          <button className='document-default-btn convert-btn'>
            <img src={convert_icon} alt='Convert Icon' />
            Convert
          </button>
          <button className='document-default-btn download-btn'>
            <img src={download_button_icon} alt='Download Button Icon' />
            Download
          </button>
          <button className='document-default-btn delete-btn'>
            <img src={delete_button_icon} alt='Delete Button Icon' />
            Delete
          </button>
        </div>
      ),
      render: () => (
        <div
          className=''
          style={{
            display: 'flex',
            alignContent: 'center',
            gap: '30px',
            justifyContent: 'end',
            flexDirection: 'row'
          }}
        >
          <div
            className='file-option'
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '30px',
              justifyContent: 'end'
            }}
          >
            <div className='formate-info'>
              <img src={format_icon} alt='Format Icon' />
              Convert File Format
            </div>
            <button className='download-file-btn'>
              <img src={download_file_icon} alt='Download File Icon' />
            </button>
            <button className='delete-file-btn'>
              <img src={delete_file_icon} alt='Delete File Icon' />
            </button>
          </div>
          <div className='more-option'>
            <Dropdown
              overlay={fileOption}
              trigger={['click']}
              placement='bottomRight'
              overlayclassNameName=' dashboard-header-menu dashboard-option-menu'
            >
              <img src={more_icon} alt='More' onClick={(e) => e.preventDefault()} />
            </Dropdown>
          </div>
        </div>
      )
    }
  ];

  // eslint-disable-next-line no-unused-vars
  const data = [
    {
      key: '1',
      fileType: <img src={pdf_png_icon} alt='' />,
      name: 'screencapture-web-archive...',
      size: '1 MB'
    },
    {
      key: '2',
      fileType: <img src={pdf_jpg_icon} alt='' />,
      name: (
        <div className='how-to-make' data-title='1 MB'>
          screencapture-web-archive...
        </div>
      ),
      size: '1 MB'
    },
    {
      key: '3',
      fileType: <img src={pdf_svg_icon} alt='' />,
      name: 'screencapture-web-archive...',
      size: '1 MB'
    },
    {
      key: '4',
      fileType: <img src={pdf_png_icon} alt='' />,
      name: 'screencapture-web-archive...',
      size: '1 MB'
    },
    {
      key: '5',
      fileType: <img src={pdf_jpg_icon} alt='' />,
      name: 'screencapture-web-archive...',
      size: '1 MB'
    },
    {
      key: '6',
      fileType: <img src={pdf_png_icon} alt='' />,
      name: 'screencapture-web-archive...',
      size: '1 MB'
    },
    {
      key: '7',
      fileType: <img src={pdf_jpg_icon} alt='' />,
      name: 'screencapture-web-archive...',
      size: '1 MB'
    },
    {
      key: '8',
      fileType: <img src={pdf_svg_icon} alt='' />,
      name: 'screencapture-web-archive...',
      size: '1 MB'
    },
    {
      key: '9',
      fileType: <img src={pdf_png_icon} alt='' />,
      name: 'screencapture-web-archive...',
      size: '1 MB'
    },
    {
      key: '10',
      fileType: <img src={pdf_jpg_icon} alt='' />,
      name: 'screencapture-web-archive...',
      size: '1 MB'
    }
  ];

  return (
    <>
      {/* <!-- Your Documents Start --> */}
      <section className='your_documents_sec'>
        <div className='main_container'>
          <div className='main_row'>
            <div className='main_col title_dock1'>
              <div className='section_title'>
                <h1>Your Documents</h1>
              </div>
            </div>
            <div className='main_col title_dock2'>
              <div className='search_bar'>
                <div className='file_search'>
                  <input type='text' name='Search' value='' placeholder='Search...' className='form-control' />
                  <div className='search-img'>
                    <img src={search_vector} alt='Search Icon' />
                  </div>
                </div>
              </div>
            </div>
            <div className='main_col title_dock3'>
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement='bottomRight'
                overlayclassNameName='upload-files-drop-box'
              >
                <div onClick={(e) => e.preventDefault()}>
                  <div className='upload_btn'>
                    <a href='#'>
                      <img src={upload_icon} alt='Upload Icon' className='upload-files-icon' />
                      Upload File
                    </a>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
          <Table
            rowSelection={{
              type: 'checkbox'
            }}
            columns={columns}
            dataSource={[]}
            className='document-table-info'
          />
        </div>
      </section>
      {/* <!-- Your Documents End -->
	<!-- Files Convert Start --> */}
      <FileConvert />
      {/* <!-- Files Convert End -->
	<!-- Work Type Start --> */}
      <section className='work_type_sec'>
        <div className='main_container'>
          <div className='work_box_main'>
            <div className='work_box work_box1'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={compress_pdf_icon} alt='Compress PDF' />
                </div>
                <p>Compress PDF</p>
                <div className='work_arrow'>
                  <img src={compress_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box2'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={merge_pgf_icon} alt='Merge PDF' />
                </div>
                <p>Merge PDF</p>
                <div className='work_arrow'>
                  <img src={merge_pgf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box3'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={split_pdf_icon} alt='Split PDF' />
                </div>
                <p>Split PDF</p>
                <div className='work_arrow'>
                  <img src={split_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box4'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={sign_pdf_icon} alt='Sign PDF' />
                </div>
                <p>Sign PDF</p>
                <div className='work_arrow'>
                  <img src={sign_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box5'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={protect_pdf_icon} alt='Protect PDF' />
                </div>
                <p>Protect PDF</p>
                <div className='work_arrow'>
                  <img src={protect_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box6'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={edit_pdf_icon} alt='Edit a PDF' />
                </div>
                <p>Edit a PDF</p>
                <div className='work_arrow'>
                  <img src={edit_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box7'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={rotate_pdf_icon} alt='Rotate' />
                </div>
                <p>Rotate</p>
                <div className='work_arrow'>
                  <img src={rotate_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box8'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={remove_pdf_icon} alt='Remove Pages' />
                </div>
                <p>Remove Pages</p>
                <div className='work_arrow'>
                  <img src={remove_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box9'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={extract_pdf_icon} alt='Extract PDF' />
                </div>
                <p>Extract PDF</p>
                <div className='work_arrow'>
                  <img src={extract_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box10'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={word_pdf_icon} alt='Word to PDF' />
                </div>
                <p>Word to PDF</p>
                <div className='work_arrow'>
                  <img src={word_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box11'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={to_word_pdf_icon} alt='PDF to Word' />
                </div>
                <p>PDF to Word</p>
                <div className='work_arrow'>
                  <img src={to_word_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
            <div className='work_box work_box12'>
              <div className='work_detl'>
                <div className='work_icon'>
                  <img src={unlock_pdf_icon} alt='Unlock PDF' />
                </div>
                <p>Unlock PDF</p>
                <div className='work_arrow'>
                  <img src={unlock_pdf_right_icon} alt='Right Icon' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Work Type End --> */}
    </>
  );
};

export default DashboardComponent;
