import { Form, Input, Modal, Spin } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import './loginModal.scss';
import { useState } from 'react';
import { Home } from '../../../pages';
import { Cookies } from 'react-cookie';
import strings from '../../../utils/strings.json';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { maxEmailLength, maxPasswordLength } from '../../../utils/commonUtils';

const LoginModal = (props) => {
  const {
    onClickOfContinue,
    handleValuesChange,
    emailError,
    loginError,
    isLoading,
    passwordError,
    setEmailErrors,
    passwordVisible,
    setPasswordVisible,
    setPasswordError
  } = props;

  const navigate = useNavigate();
  const cookie = new Cookies();
  const [form] = Form.useForm();
  const [isLoginOpen, setIsLoginOpen] = useState(true);
  const [lang] = useState(cookie.get('lang') || 'en');
  const handleClose = () => {
    document.body.style.zoom = '100%';
    setIsLoginOpen(false);
    navigate(`/${lang}`);
  };

  return (
    <div>
      <Home />

      <Modal
        centered
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
        open={isLoginOpen}
        onCancel={handleClose}
        className='login-info-wrap'
      >
        <Spin spinning={isLoading}>
          <div className='login-modal-section'>
            <div className='login-modal-title'>
              <span>Login</span>
            </div>
            <div className='wrap-top-modal'>
              <div className='google-button-login'>
                <button className='login-bg-wrap login-with-google-btn'>Continue with Google</button>
              </div>
              <div className='google-button-login'>
                <button className='login-bg-wrap login-with-apple-btn'>Continue with Apple</button>
              </div>
              <div className='google-button-login'>
                <button className='login-bg-wrap login-with-facebook-btn'>Continue with Facebook</button>
              </div>
              <div className='auth-divider login-auth-divider'>
                <span className='line left'></span>
                <span className='or-text'>Or, login with email</span>
                <span className='line right'></span>
              </div>
              <Form
                form={form}
                onFinish={onClickOfContinue}
                initialValues={{ email: '', password: '' }}
                onValuesChange={handleValuesChange}
              >
                <div className='body-modal'>
                  <div className='modal-login-form'>
                    <div className='mb'>
                      <Form.Item
                        label={
                          <span>
                            {strings.EMAIL_ADDRESS} <span style={{ color: 'red' }}>*</span>{' '}
                          </span>
                        }
                        name='email'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          className={`${emailError || loginError ? 'errorFelid' : ''} "payment-inputs"`}
                          name='email'
                          placeholder='hello@mail.com'
                          onChange={() => setEmailErrors(null)}
                          maxLength={maxEmailLength}
                        />
                      </Form.Item>
                      {emailError && <p style={{ margin: '0', color: '#ff4d4f' }}>{emailError}</p>}
                      {!emailError && loginError && (
                        <p
                          style={{
                            margin: '0',
                            color: '#ff4d4f',
                            marginTop: 10
                          }}
                        >
                          {loginError}
                        </p>
                      )}
                    </div>
                    <Form.Item
                      label={
                        <span>
                          {strings.PASSWORD} <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name='password'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <div className='password-input-container'>
                        <Input
                          className={`payment-inputs ${passwordError ? 'errorFelid' : ''}`}
                          name='password'
                          type={passwordVisible ? 'text' : 'password'}
                          placeholder='*****'
                          maxLength={maxPasswordLength}
                          onChange={() => setPasswordError(null)}
                        />
                        <span className='password-toggle-icon' onClick={() => setPasswordVisible(!passwordVisible)}>
                          {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                        </span>
                      </div>
                    </Form.Item>
                    {passwordError && <p style={{ margin: '0', color: '#ff4d4f' }}>{passwordError}</p>}
                  </div>
                  <div className='submit-btn-modal'>
                    <button type='submit' className='hl_cta_wrap'>
                      Continue
                    </button>
                  </div>
                  <div className='forget-pass-modal'>
                    <Link to={`/${lang}/reset-password`}>
                      <p>Forgot Password?</p>
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default LoginModal;
