import React, { useState } from 'react';
import './filesConvert.scss';
import { drive_icon, link_icon } from '../../../../assets/images/home';
import { pdf_png_icon, pdf_svg_icon } from '../../../../assets/images/dashboard';
import {
  box_icon,
  Cancle_button,
  down_arrows,
  drop_box_image,
  right_arrows,
  file_convert_icon,
  document_flow_icon
} from '../../../../assets/images/dashboard';
import {
  APP_KEY_DROPBOX,
  capitalizeFirstLetter,
  dropboxExtensions,
  formatFileSize
} from '../../../../utils/commonUtils';
import { Progress, Spin } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import PaymentModal from '../../../modals/paymentModal/paymentModal';
import { FileIcon, defaultStyles } from 'react-file-icon';
import UrlModal from '../../../modals/urlModal/urlModal';
import strings from '../../../../utils/strings.json';
import { ConverterDropdown } from './converterDropdown';
import DropboxChooser from 'react-dropbox-chooser';
import { FaExclamationTriangle } from 'react-icons/fa';
import { getProgressDetails } from './helper';

const FileConvertComponent = (props) => {
  const {
    move,
    percent,
    setisOpen,
    isOpen,
    checkoutModalOpen,
    clientSecret,
    options,
    stripePromise,
    customer,
    setCheckoutModalOpen,
    isLoading,
    handleOpenPicker,
    handleRegister,
    setOpenDropdowns,
    selectedFiles,
    openDropdowns,
    handleFileChange,
    handleDeleteFile,
    handleDrop,
    handleDragOver,
    handleGetURL,
    validationErrors,
    handleSelectConvertTo,
    limitExtendMessage,
    handleSuccess,
    redirectToDropboxAuth,
    dropboxAccessToken,
    conversionErrors,
    setConversionErrors
  } = props;
  
 const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleDropdown = (fileName) => {
    setConversionErrors({});
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileName]: !prevState[fileName]
    }));
  };

  return (
    <>
      <section className='banner-section'>
        <div className='main_container'>
          <div className='banner-content'>
            <h1>Files Convert</h1>
            <p>Convert archives, documents, images, videos, audio, websites and more online.</p>
          </div>
          {move ? (
            <div>
              <div className='drop-box-move-section'>
                <div className='drag-drop-inner'>
                  <div className='drag-drop-element'>
                    <div className='drop-box-move-inner'>
                      <div className='box-move-head-content'>
                        <div className='convert-box-content'>
                          <img src={pdf_png_icon} alt='Drop Box' />
                          <span>PNG</span>
                        </div>
                        <div className='convert-box-content'>
                          <img src={file_convert_icon} alt='Drop Box' />
                        </div>
                        <div className='convert-box-content'>
                          <img src={pdf_svg_icon} alt='Drop Box' />
                          <span>SVG</span>
                        </div>
                      </div>
                      <div className='drop-box-convert-icon'>
                        <img src={document_flow_icon} alt='Drop Box' />
                      </div>
                      <div className='drop-box-convert-content'>
                        <h3>Converting Files.... {percent}%</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div className='drop-box-progress-bar'>
                          <div className='progress-bar-inner'>
                            <Progress
                              id='progressBar'
                              strokeColor={{
                                from: '#0076CE',
                                to: '#0076CE'
                              }}
                              percent={percent}
                              showInfo={false}
                              status='active'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spin spinning={isLoading}>
              <div className='drag-drop-inner'>
                <div className='drag-drop-element'>
                  <div
                    className='drop-area'
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    style={{
                      border: '2px dashed #ccc',
                      borderRadius: '5px',
                      padding: '20px',
                      textAlign: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    <input
                      type='file'
                      id='drag_drop_file'
                      multiple
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor='drag_drop_file'>Drag & Drop files here to upload</label>
                  </div>
                </div>
                <div className='banner-device-element'>
                  <div className='device-icon'>
                    <div className='device-icon-inner'>
                      <img src={drop_box_image} alt='Drop Box' />
                      {dropboxAccessToken ? (
                        <DropboxChooser
                          appKey={APP_KEY_DROPBOX}
                          success={handleSuccess}
                          cancel={() => {}}
                          multiselect={true} // Allow only multiple file
                          extensions={dropboxExtensions}
                          linkType='preview'
                        >
                          <span>{strings.DROPBOX}</span>
                        </DropboxChooser>
                      ) : (
                        <span onClick={redirectToDropboxAuth}>{strings.AUTHENTICATE_DROPBOX}</span>
                      )}
                    </div>
                  </div>
                  <div className='device-icon'>
                    <div className='device-icon-inner' onClick={() => setIsModalVisible(true)}>
                      <img src={link_icon} alt='Link' />
                      <span>Get From URL</span>
                    </div>
                  </div>
                  <div className='device-icon'>
                    <div className='device-icon-inner' onClick={handleOpenPicker}>
                      <img src={drive_icon} alt='Google Drive' />
                      <span>Google Drive</span>
                    </div>
                  </div>
                  <div className='device-icon'>
                    <div className='device-icon-inner'>
                      <img src={box_icon} alt='Box' />
                      <span>Box</span>
                    </div>
                  </div>
                </div>
                {selectedFiles.length > 0 && (
                  <div className='file-convert-list-main'>
                    <div className='your-file single_line'>
                      <span>Your Files</span>
                      {limitExtendMessage && <p style={{ color: 'red' }}>{limitExtendMessage}</p>}
                    </div>
                    <div className='file-convert-list-main'>
                      {selectedFiles.slice(0, 20).map(({ id, file: { name, size }, status, percent, convertedTo }) => {
                        const fileErrorMessage = conversionErrors[id];
                        const { progressStatus, strokeColor, animatedClass, fileExtension } = getProgressDetails({
                          percent,
                          status,
                          name,
                          strings
                        });

                        return (
                          <div className='wpb_content-wrapper' key={id}>
                            <div className='convert-formate-main'>
                              <div className='file-converting-main'>
                                <div className='file-convert-img' style={{ width: '40px', height: '40px' }}>
                                  <FileIcon
                                    extension={fileExtension}
                                    {...defaultStyles[fileExtension]}
                                    style={{ width: '100%', height: '100%' }}
                                  />
                                </div>
                                <div className='file-list-inner'>
                                  <p>{name}</p>
                                  <span>{formatFileSize(size)}</span>
                                  <span
                                    className={`errorclass ${status !== strings.SUCCESS && status !== strings.PENDING ? 'error-style' : ''}`}
                                  >
                                    {status !== strings.SUCCESS && status !== strings.PENDING && (
                                      <>
                                        <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                                        {`${capitalizeFirstLetter(status)}`}
                                      </>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className='file-actions'>
                                <div className='convert-to-main'>
                                  {(status === strings.SUCCESS || status === strings.PENDING) && (
                                    <div className='convert-formate'>
                                      <span>{fileExtension}</span>
                                      <div className='convert-img'>
                                        <img src={right_arrows} alt='' />
                                      </div>
                                      <div className='convert-option'>
                                        <div onClick={() => toggleDropdown(id)}>
                                          {convertedTo || strings.CONVERT_TO}
                                          <img src={down_arrows} alt='' />
                                        </div>
                                        <span className={`dropdown-error ${validationErrors[id] ? 'has-error' : ''}`}>
                                          {validationErrors[id] && (
                                            <>
                                              <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                                              <span>{validationErrors[id]}</span>
                                            </>
                                          )}
                                        </span>
                                        {fileErrorMessage && (
                                          <span className={`dropdown-error conversion-error`}>
                                            {fileErrorMessage && (
                                              <>
                                                <FaExclamationTriangle style={{ color: 'red', marginRight: '4px' }} />
                                                {fileErrorMessage}
                                              </>
                                            )}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {openDropdowns[id] && (
                                    <ConverterDropdown
                                      setisOpen={() => toggleDropdown(id)}
                                      onSelectConvertTo={(option) => handleSelectConvertTo(id, option)}
                                    />
                                  )}
                                  <div className='convert-close'>
                                    <button className='close_imgfield' onClick={() => handleDeleteFile(id)}>
                                      <img src={Cancle_button} alt='' />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Progress
                              className={`wpb_files__progress ${animatedClass}`}
                              percent={percent}
                              status={progressStatus}
                              strokeColor={strokeColor}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {isOpen && <ConverterDropdown setisOpen={setisOpen} />}
                {selectedFiles.length > 0 && selectedFiles.some((file) => file.status === strings.SUCCESS) && (
                  <div className='wpb_convert-btn'>
                    <button onClick={handleRegister} className={isLoading ? 'btn_disable' : ''} disabled={isLoading}>
                      {strings.CONVERT}
                    </button>
                  </div>
                )}
              </div>
            </Spin>
          )}
        </div>
      </section>
      {checkoutModalOpen && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentModal
            clientSecret={clientSecret}
            checkoutModalOpen={checkoutModalOpen}
            setCheckoutModalOpen={setCheckoutModalOpen}
            customer={customer}
          />
        </Elements>
      )}
      <UrlModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} handleGetURL={handleGetURL} />
    </>
  );
};

export default FileConvertComponent;
