import React, { useEffect, useState } from 'react';
import FileConvertComponent from './filesConvert';
import { loadStripe } from '@stripe/stripe-js';
import { stripeLoadScript, driveClientId, driveDevelopmentKey, clientSecretDropbox } from '../../../../environment';
import Notify from '../../../common/notify';
import { APP_KEY_DROPBOX, appearance, currency, redirectUri } from '../../../../utils/commonUtils';
import { paymentIntent } from '../../../../redux/slice/paymentIntentSlice';
import { connect } from 'react-redux';
import { uploadFile } from '../../../../redux/slice/uploadFileSlice';
import useDrivePicker from 'react-google-drive-picker';
import { urlValidation } from '../../../../redux/slice/urlValidationSlice';
import { useSession } from '../../../../context/SessionContext';
import { saveAnalytics } from '../../../../redux/slice/saveAnalyticsSlice';
import { Cookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import strings from '../../../../utils/strings.json';

const stripePromise = loadStripe(stripeLoadScript);
const FileConvert = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [move, setMove] = useState(false);
  const [percent, setPercent] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [customer, setCustomer] = useState('');
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [openPicker, authResponse] = useDrivePicker();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadInProgress, setUploadInProgress] = useState(false); // New flag to track upload state
  const [convertOptions, setConvertOptions] = useState({});
  const [validationErrors, setValidationErrors] = useState({}); // State for validation errors
  const [limitExtendMessage, setLimitExtendMessagee] = useState('');
  const { callpaymentIntent, callUploadFile, callurlValidation, callSaveAnalytics } = props;
  const options = {
    clientSecret,
    appearance,
    locale: 'en'
  };
  const { sessionId } = useSession();
  const [dropboxAccessToken, setDropboxAccessToken] = useState('');
  const [conversionErrors, setConversionErrors] = useState({});
  const cookies = new Cookies();
  const token = cookies.get('token');
  const [googleAccessToken, setGoogleAccessToken] = useState();
  // const APP_KEY = apiKeyDropbox.replace(/['",]/g, ''); // Replace with your Dropbox App Key
  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return 150;
        }
      });
    }, 150);

    return () => {
      clearInterval(interval);
      setMove(false);
    };
  }, []);

  useEffect(() => {
    const pendingFiles = selectedFiles.filter((file) => file.status === strings.PENDING);
    if (pendingFiles.length > 0 && !uploadInProgress) {
      setUploadInProgress(true);
      handleUpload(pendingFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  useEffect(() => {
    if (percent === 100) {
      setMove(false);
    }
  }, [percent]);

  const handleRegister = async () => {
    setLoading(true);

    if (validateConvertToSelection()) {
      const newFiles = selectedFiles
        .filter((item) => item.status === 'success')
        .map((item) => ({
          url: item.url,
          convertFrom: item.convertedFrom,
          convertTo: item.convertedTo?.toLowerCase(),
          size: item.file.size,
          fileConvertId: item.fileConvertId,
          ...(item.file.name && { fileOriginalName: item.file.name })
        }));

      const requestData = {
        sessionId: sessionId,
        files: newFiles
      };
      try {
        let result = await callSaveAnalytics(requestData);
        if (result?.type === 'saveAnalytics/fulfilled') {
          const intentdata = {
            amount: 50,
            currency: currency
          };
          if (result?.payload?.data?.success === false) {
            const errors = {};
            const filesToProcess = [...selectedFiles];
            result?.payload?.data.notAllowed.forEach(({ fileName, message }) => {
              const fileIndex = filesToProcess.findIndex((file) => file.file.name === fileName);
              if (fileIndex !== -1) {
                const fileWithError = filesToProcess[fileIndex];
                errors[fileWithError.id] = message;

                filesToProcess.splice(fileIndex, 1);
              }
            });
            setConversionErrors(errors);
          } else {
            Notify('success', result?.payload?.message, '');
            setSelectedFiles([]);
            if (!token) {
              const paymentResult = await callpaymentIntent(intentdata);
              const { type, payload, error } = paymentResult || {};
              if (type === 'paymentIntent/fulfilled') {
                setCustomer(payload.data.customer);
                setClientSecret(payload.data.client_secret);
                setCheckoutModalOpen(true);
              } else {
                Notify('error', error.message, '');
              }
            }
          }
        } else {
          const errors = {};

          result?.payload?.data.notAllowed.forEach(({ fileName, fileExtension, fileConvertTo, message }) => {
            const fileWithError = selectedFiles.find(
              (file) =>
                file.file.name === fileName &&
                file.file.extension === fileExtension &&
                file.convertedTo?.toLowerCase() === fileConvertTo?.toLowerCase()
            );

            if (fileWithError) {
              errors[fileWithError.id] = message;
            }
          });
          setConversionErrors(errors);
          Notify('error', result?.error.message, '');
        }
      } catch (error) {
        Notify('error', error.message, '');
      } finally {
        setLoading(false);
      }
    }
  };



  
  useEffect(() => {
    if (authResponse?.access_token && selectedFile) {
      // Proceed with uploading the file when access token is available
      handleUploadFile(selectedFile, selectedFile.downloadLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authResponse, selectedFile]);

  useEffect(() => {
    if (authResponse?.access_token) {
      setGoogleAccessToken(authResponse.access_token)
    }
  }, [authResponse]);

  const handleOpenPicker = () => {
    openPicker({
      clientId:driveClientId?.replace(/['",]/g,'') ,
      developerKey: driveDevelopmentKey?.replace(/['",]/g,''),
      viewId: 'DOCS',
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      token: googleAccessToken,

      multiselect: false,
      callbackFunction: (data) => {
        if (data && data.action === 'cancel') {
          return;
        }

        if (data && data.docs && data.docs.length > 0) {
          const file = data.docs[0];
          const downloadLink = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
          setSelectedFile({
            ...file,
            downloadLink
          });
        } else {
          console.log('No file selected or invalid response');
        }
      }
    });
  };
  const handleUploadFile = async (file, downloadLink) => {
    try {
      const response = await fetch(downloadLink, {
        headers: {
          Authorization: `Bearer ${googleAccessToken}`
        }
      });
    const blob = await response.blob();
    const formData = new FormData();
     // Convert blob to File object for further upload processing
     const fileToUpload = new File([blob], file.name, { type: blob.type });
     formData.append('file', fileToUpload);
     formData.append('sessionId', sessionId);
     const result = await callUploadFile(formData);
     let simulatedPercent = 0;
     const interval = setInterval(() => {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === file.id && simulatedPercent < 95 ? { ...f, percent: (simulatedPercent += 5) } : f
        )
      );
    }, 200);

     const { type: uploadType, payload: uploadPayload, error: uploadError } = result || {};

     if (uploadType === 'uploadFile/fulfilled' && uploadPayload.success) {
      clearInterval(interval);
       Notify('success', uploadPayload.message, '');
       let { fileOriginalName, size, _id } = uploadPayload.data;
       let data = {
         id: _id,
         file: { name: fileOriginalName, size: size },
         status: strings.SUCCESS,
                  percent: 100,
       };
       setSelectedFiles((prevFiles) => [...prevFiles, data]);
     } else {
       Notify('error', uploadError.message, '');
     }
    } catch (error) {
      console.log("error.message")
    }
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map((file) => {
      const status = file.size > 20 * 1024 * 1024 ? strings.FILE_SIZE_EXCEEDS_20_MB : strings.PENDING;

      return {
        file,
        id: uuidv4(),
        status,
        percent: status === strings.PENDING ? 0 : 100
      };
    });

    setSelectedFiles((prevFiles) => {
      const totalFiles = prevFiles.length + newFiles.length;

      if (totalFiles > 20) {
        setLimitExtendMessagee(strings.FILE_LIMIT_ERROR_MESSSGE);
        const availableSlots = 20 - prevFiles.length;
        return [...prevFiles, ...newFiles.slice(0, availableSlots)];
      }

      setLimitExtendMessagee('');
      return [...prevFiles, ...newFiles];
    });

    event.target.value = null; // Reset the file input
  };

  const handleDeleteFile = (fileId) => {
    setLimitExtendMessagee('');
    setSelectedFiles((prevFiles) => prevFiles.filter((fileObj) => fileObj.id !== fileId));
  };

  const uploadBrowsedFile = async (fileObj) => {
    const formData = new FormData();
    const blob = new Blob([fileObj.file], { type: fileObj.file.type });
    formData.append('file', blob, fileObj.file.name);
    formData.append('sessionId', sessionId);
    let simulatedPercent = 0;
    const interval = setInterval(() => {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === fileObj.id && simulatedPercent < 95 ? { ...f, percent: (simulatedPercent += 5) } : f
        )
      );
    }, 200);

    try {
      const result = await callUploadFile(formData);
      if (result?.type === 'uploadFile/fulfilled') {
        clearInterval(interval); // Clear interval on success
        setSelectedFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === fileObj.id
              ? {
                  ...f,
                  status: strings.SUCCESS,
                  percent: 100,
                  url: result?.payload?.data?.url,
                  convertedFrom: fileObj?.file?.name?.split('.').pop(),
                  convertedTo: fileObj?.convertedTo?.toLowerCase(),
                  fileConvertId: result?.payload?.data?._id
                } // Set to 100% when upload succeeds
              : f
          )
        );
      } else {
        clearInterval(interval);
        setSelectedFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === fileObj.id
              ? { ...f, status: result?.error?.message || 'failed', percent: 100 } // Reset percent on error
              : f
          )
        );
      }
    } catch (error) {
      clearInterval(interval);
      setSelectedFiles((prevFiles) =>
        prevFiles.map((f) => (f.id === fileObj.id ? { ...f, status: 'failed', percent: 100 } : f))
      );
    }
  };

  const handleUpload = async (pendingFiles) => {
    for (const fileObj of pendingFiles) {
      await uploadBrowsedFile(fileObj);
    }
    setUploadInProgress(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleFileChange({ target: { files } }); // Pass the files to handleFileChange
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default to allow drop
  };

  const handleGetURL = async (url, onClose, setError) => {
    try {
      const urlValidationResult = await callurlValidation({ url });
      const { type: validationType, payload: validationPayload, error: validationError } = urlValidationResult || {};
      if (validationType === 'urlValidation/fulfilled') {
        const { filename, size, url: validatedUrl } = validationPayload.data;
        const formData = new FormData();
        formData.append('fileUrl', validatedUrl);
        formData.append('category', 'publicUrl');
        formData.append('sessionId', sessionId);
        formData.append('size', size);
        formData.append('name', filename);
        const uploadResult = await callUploadFile(formData);
        const { type: uploadType, payload: uploadPayload, error: uploadError } = uploadResult || {};
        if (uploadType === 'uploadFile/fulfilled' && uploadPayload.success) {
          Notify('success', uploadPayload.message, '');
          let { sessionId, fileOriginalName, size } = uploadPayload.data;
          let data = {
            id: sessionId,
            file: { name: fileOriginalName, size: size },
            status: strings.SUCCESS,
            percent: '100'
          };
          setSelectedFiles((prevFiles) => [...prevFiles, data]);
          onClose(); // Close the modal only after successful upload
        } else {
          setError(uploadError.message);
        }
      } else {
        setError(validationError.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const validateConvertToSelection = () => {
    setLoading(false);

    const errors = {};
    selectedFiles.forEach((file) => {
      if (file.status === strings.SUCCESS && !convertOptions[file.id]) {
        errors[file.id] = 'Required';
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSelectConvertTo = (fileId, convertToOption) => {
    setConvertOptions((prev) => ({
      ...prev,
      [fileId]: convertToOption
    }));
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[fileId];
      return newErrors;
    });
    const updatedFiles = selectedFiles.map((file) => {
      if (file.id === fileId) {
        return { ...file, convertedTo: convertToOption?.toLowerCase() };
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [fileId]: false
    }));
  };

  useEffect(() => {
    const fetchAccessToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          await exchangeCodeForToken(code);
        } catch (error) {
          console.error('Error exchanging code for token:', error);
        }
      }
    };

    fetchAccessToken();
  }, []);
  const redirectToDropboxAuth = () => {
    const dropboxAuthUrl = `https://www.dropbox.com/oauth2/authorize?response_type=code&client_id=${APP_KEY_DROPBOX}&redirect_uri=${redirectUri}`;
    window.location.href = dropboxAuthUrl;
  };
  const exchangeCodeForToken = async (code) => {
    const clientId = APP_KEY_DROPBOX;
    const clientSecret = clientSecretDropbox;
    try {
      const response = await fetch(`https://api.dropboxapi.com/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          code,
          grant_type: 'authorization_code',
          client_id: clientId,
          client_secret: clientSecret,
          redirect_uri: redirectUri
        })
      });

      const data = await response.json();
      setDropboxAccessToken(data.access_token);
    } catch (error) {
      console.error('Error exchanging code for token:', error);
    }
  };
  const handleSuccess = async (files) => {
    if (files.length === 0) {
      return;
    }

    try {
      setLoading(true);

      // Loop through the files and process each
      for (let file of files) {
        await getDropboxSelectedFile(file);
      }
    } catch (error) {
      console.error('Error processing files:', error);
    } finally {
      setLoading(false);
      setisOpen(false);
    }
  };

  const getDropboxSelectedFile = async (file) => {
    setLoading(true);
    try {
      // Use Dropbox API to get a temporary link for the file (if needed)
      const response = await fetch('https://api.dropboxapi.com/2/files/get_temporary_link', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${dropboxAccessToken}`, // Make sure access token is valid
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ path: file.id }) // Send file path/id to retrieve
      });

      if (!response.ok) {
        Notify('error', strings.DROPBOX_TEMPORARY_LINK_ERROR, '');
      }

      const data = await response.json();
      const downloadResponse = await fetch(data.link); // Use temporary link to download file
      const blob = await downloadResponse.blob(); // Convert to blob

      // Convert blob to File object for further upload processing
      const fileToUpload = new File([blob], file.name, { type: blob.type });
      const formData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('sessionId', sessionId);
      const result = await callUploadFile(formData);
      const { type: uploadType, payload: uploadPayload, error: uploadError } = result || {};

      if (uploadType === 'uploadFile/fulfilled' && uploadPayload.success) {
        Notify('success', uploadPayload.message, '');
        let { fileOriginalName, size, _id } = uploadPayload.data;
        let data = {
          id: _id,
          file: { name: fileOriginalName, size: size },
          status: 'success',
          percent: '100'
        };
        setSelectedFiles((prevFiles) => [...prevFiles, data]);
      } else {
        Notify('error', uploadError.message, '');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setisOpen(false);
    }
  };
  // Dropbox is open in single click start
  useEffect(() => {
    // Load Dropbox SDK only once
    const loadDropboxSDK = async () => {
      if (!window.Dropbox) {
        const script = document.createElement('script');
        script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
        script.setAttribute('data-app-key', APP_KEY_DROPBOX);
        document.body.appendChild(script);
        await new Promise((resolve, reject) => {
          script.onload = resolve;
          script.onerror = reject;
        });
      }
    };
    const openDropboxChooser = async () => {
      if (dropboxAccessToken) {
        try {
          await loadDropboxSDK();

          // Open Dropbox Chooser if SDK is loaded
          window.Dropbox.choose({
            success: handleSuccess,
            cancel: () => {},
            linkType: 'preview',
            multiselect: true,
            extensions: ['.pdf', '.doc', '.docx', '.jpg', '.png', '.json', '.js']
          });
        } catch (error) {
          console.error('Error loading Dropbox SDK:', error);
        }
      }
    };
    openDropboxChooser();
  }, [dropboxAccessToken]);
  //  Dropbox is open in single click end
  return (
    <FileConvertComponent
      move={move}
      setLoading={setLoading}
      isLoading={isLoading}
      percent={percent}
      setisOpen={setisOpen}
      isOpen={isOpen}
      handleRegister={handleRegister}
      checkoutModalOpen={checkoutModalOpen}
      clientSecret={clientSecret}
      options={options}
      stripePromise={stripePromise}
      customer={customer}
      setCheckoutModalOpen={setCheckoutModalOpen}
      handleOpenPicker={handleOpenPicker}
      handleUpload={handleUpload}
      setOpenDropdowns={setOpenDropdowns}
      openDropdowns={openDropdowns}
      selectedFiles={selectedFiles}
      handleFileChange={handleFileChange}
      handleDeleteFile={handleDeleteFile}
      handleDrop={handleDrop}
      handleDragOver={handleDragOver}
      handleGetURL={handleGetURL}
      setSelectedFiles={setSelectedFiles}
      validationErrors={validationErrors}
      handleSelectConvertTo={handleSelectConvertTo}
      limitExtendMessage={limitExtendMessage}
      handleSuccess={handleSuccess}
      redirectToDropboxAuth={redirectToDropboxAuth}
      dropboxAccessToken={dropboxAccessToken}
      conversionErrors={conversionErrors}
      setConversionErrors={setConversionErrors}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callpaymentIntent: (data) => dispatch(paymentIntent(data)),
    callUploadFile: (data) => dispatch(uploadFile(data)),
    callurlValidation: (data) => dispatch(urlValidation(data)),
    callSaveAnalytics: (data) => dispatch(saveAnalytics(data))
  };
};

export default connect(null, mapDispatchToProps)(FileConvert);
