import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import "./urlModal.scss"
import strings from '../../../utils/strings.json';

const UrlModal = ({ visible, onClose, handleGetURL }) => {
  const [url, setUrl] = useState(''); // State to store input value
  const [error, setError] = useState(false); // State to store error message

  const handleChange = (e) => {
    setUrl(e.target.value); // Update state when input changes
    setError('');
  };

  const handleAddClick = () => {
    if (!url || url.trim() === '') { 
      setError(strings.INVALID_URL);
      return;
    }
    handleGetURL(url,onClose,setError); // Pass the input value to handleGetURL when "Add" is clicked
    setUrl();
    setError('');
  };
const handleClose=()=>{
  onClose();
  setUrl();
  setError('');
}
  return (
     <Modal
      visible={visible}
      onOk={onClose}
      onCancel={handleClose}
      footer={null} // Remove default footer
      className="modal-content urL-modal"
    >
        <div className="custom-modal-header">
        <h2 className="custom-modal-title">{strings.GET_URL}</h2>
        </div>
        <div className="custom-modal-content">
      <Input
        placeholder={strings.URL_TEXT}
        value={url}
        onChange={handleChange} 
        status={error ? 'error' : ''}
        className="custom-input"
      />
         {error && <div className="error-message">{error}</div>} 
      <div className="custom-button">
        <Button type="primary" className="custom-ant-btn" onClick={handleAddClick}>
        {strings.ADD_BUTTON}
        </Button>
      </div>
      </div>
    </Modal>
  );
};

export default UrlModal;

