import { useEffect, useRef, useState } from 'react';
import { cancle_btn } from '../../../../assets/images/dashboard';
import Search from 'antd/es/input/Search';
import { fields, stripeOptions } from '../../../../utils/commonUtils';

export const ConverterDropdown = (props) => {
  const { setisOpen, onSelectConvertTo } = props;
  const [selectedField, setSelectedField] = useState('All');
  const dropdownRef = useRef(null);

  const handleFieldChange = (field) => {
    setSelectedField(field);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setisOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setisOpen]);

  return (
    <div className='custom-select-popup' ref={dropdownRef}>
      <div className='popup'>
        <div className='close-btn'>
          <img src={cancle_btn} alt='Close' onClick={() => setisOpen(false)} />
        </div>
        <div className='popup-row'>
          <Search placeholder='Search...' style={{ width: '100%' }} />
        </div>
        <div className='popup-row columns'>
          <div className='column first-column'>
            <div className='fields-list'>
              {fields.map((field, index) => (
                <div
                  key={index}
                  className={`field-item ${selectedField === field.field ? 'selected' : ''}`}
                  onClick={() => handleFieldChange(field.field)}
                >
                  <img src={field.image} alt='' />
                  <span>{field.field}</span>
                </div>
              ))}
            </div>
          </div>
          <div className='column second-column'>
            {selectedField && (
              <ul>
                {stripeOptions[selectedField].map((option, index) => (
                  <li key={index} onClick={() => onSelectConvertTo(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
